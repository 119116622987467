var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"view-sittings"}},[(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('validation-provider',{attrs:{"rules":"uuid","name":"User ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-account","label":"Search by User ID","dense":""},model:{value:(_vm.sitting_user_profile_id),callback:function ($$v) {_vm.sitting_user_profile_id=$$v},expression:"sitting_user_profile_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"uuid","name":"Assessment Instance ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-note","label":"Assessment Instance ID","dense":""},model:{value:(_vm.assessment_instance_id),callback:function ($$v) {_vm.assessment_instance_id=$$v},expression:"assessment_instance_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"alpha_dash|min:3","name":"Assessment Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"prepend-icon":"mdi-magnify","label":"Assessment Type","dense":""},model:{value:(_vm.assessment_type),callback:function ($$v) {_vm.assessment_type=$$v},expression:"assessment_type"}})]}}],null,true)}),_c('v-btn',{staticClass:"ml-auto mr-2",attrs:{"disabled":invalid || _vm.isBusy,"color":"primary","type":"submit"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")]),_vm._v(" Search Sittings ")],1),_c('v-btn',{attrs:{"color":"primary","type":"reset","disabled":_vm.isBusy},on:{"click":function($event){$event.preventDefault();return _vm.clearFilter($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-autorenew")]),_vm._v(" Clear ")],1)],1)]}}])}),_c('v-card',{staticClass:"mb-6"},[_c('v-data-table',{attrs:{"fixed-header":"","items-per-page":-1,"height":"70vh","loading-text":"Loading sittings ...","no-data-text":"No sittings found.","sort-by":"started_time","must-sort":true,"sort-desc":true,"headers":_vm.headers,"items":_vm.sittings,"loading":_vm.isBusy,"header-props":{ sortIcon: 'mdi-chevron-down' },"footer-props":{ 'disable-items-per-page': true }},scopedSlots:_vm._u([{key:"item.started_time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateString")(item.started_time)))])]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"uuid",attrs:{"to":{ path: '/users', query: { search: item.user_id } }}},[_vm._v(_vm._s(item.user_id))])]}},{key:"item.sitting_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"uuid",attrs:{"to":{ path: ("/sittings/" + (item.sitting_id)) }}},[_vm._v(_vm._s(item.sitting_id))])]}},{key:"item.assessment_instance_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"uuid",attrs:{"to":{ path: ("/assessment-instances/" + (item.assessment_instance_id) + "/items") }}},[_vm._v(_vm._s(item.assessment_instance_id))])]}},{key:"item.assessment_type_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"uuid",attrs:{"to":{ path: '/assessment-instances', query: { assessment_type_id: item.assessment_type_id } }}},[_vm._v(_vm._s(item.assessment_type_name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("status")(item.status)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions d-flex mb-2"},[_c('div',{staticClass:"ml-auto mr-2"},[_c('v-btn',{attrs:{"title":"Proctor sittings for this user","dark":"","color":"amber","small":"","to":{ path: '/proctoring', query: { search: item.user_id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-multiple")])],1)],1),_c('div',[_c('v-btn',{attrs:{"title":"View the User Events for this sitting","dark":"","color":"accent","small":"","to":{ path: '/user-events', query: { uid: item.user_id, ed: _vm.eventDate(item.started_time) } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-group")])],1)],1)]),_c('div',{staticClass:"actions d-flex ml-auto"},[_c('div',{staticClass:"ml-auto mr-2"},[_c('v-btn',{attrs:{"title":"View the Assessment Instance for this sitting","dark":"","color":"indigo","small":"","to":{ path: ("/assessment-instances/" + (item.assessment_instance_id) + "/items") }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-note")])],1)],1),_c('div',[_c('v-btn',{attrs:{"title":"View responses for this sitting","dark":"","color":"teal","small":"","to":{ path: ("/sittings/" + (item.sitting_id)) }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-view-list-outline")])],1)],1)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }