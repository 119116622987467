<template>
  <v-app id="view-sittings">
    <p class="error" v-if="error">{{ error.message }}</p>
    <validation-observer ref="form" v-slot="{ invalid }">
      <form @submit.prevent="handleSubmit" class="d-flex">
        <validation-provider
          rules="uuid"
          name="User ID"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="sitting_user_profile_id"
            prepend-icon="mdi-account"
            label="Search by User ID"
            dense
          />
        </validation-provider>
        <validation-provider
          rules="uuid"
          name="Assessment Instance ID"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="assessment_instance_id"
            prepend-icon="mdi-note"
            label="Assessment Instance ID"
            dense
          />
        </validation-provider>
        <validation-provider
          rules="alpha_dash|min:3"
          name="Assessment Type"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="assessment_type"
            prepend-icon="mdi-magnify"
            label="Assessment Type"
            dense
          />
        </validation-provider>
        <v-btn
          :disabled="invalid || isBusy"
          color="primary"
          type="submit"
          class="ml-auto mr-2"
        >
          <v-icon small>mdi-filter</v-icon> Search Sittings
        </v-btn>
        <v-btn
          color="primary"
          type="reset"
          :disabled="isBusy"
          @click.prevent="clearFilter"
        >
          <v-icon small>mdi-autorenew</v-icon> Clear
        </v-btn>
      </form>
    </validation-observer>

    <v-card class='mb-6'>
      <v-data-table
        fixed-header
        :items-per-page='-1'
        height="70vh"
        loading-text='Loading sittings ...'
        no-data-text='No sittings found.'
        sort-by='started_time'
        :must-sort='true'
        :sort-desc='true'
        :headers='headers'
        :items="sittings"
        :loading="isBusy"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        :footer-props="{ 'disable-items-per-page': true }"
      >
        <template v-slot:[`item.started_time`]="{ item }">
          <span>{{ item.started_time | dateString }}</span>
        </template>
        <template v-slot:[`item.user_id`]="{ item }">
          <router-link class="uuid" :to="{ path: '/users', query: { search: item.user_id } }">{{ item.user_id }}</router-link>
        </template>
        <template v-slot:[`item.sitting_id`]="{ item }">
          <router-link class="uuid" :to="{ path: `/sittings/${item.sitting_id}` }">{{ item.sitting_id }}</router-link>
        </template>
        <template v-slot:[`item.assessment_instance_id`]="{ item }">
          <router-link class="uuid" :to="{ path: `/assessment-instances/${item.assessment_instance_id}/items` }">{{ item.assessment_instance_id }}</router-link>
        </template>
        <template v-slot:[`item.assessment_type_name`]="{ item }">
          <router-link class="uuid" :to="{ path: '/assessment-instances', query: { assessment_type_id: item.assessment_type_id } }">{{ item.assessment_type_name }}</router-link>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | status }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex mb-2">
            <div class="ml-auto mr-2">
              <v-btn title="Proctor sittings for this user" dark color="amber" small :to="{ path: '/proctoring', query: { search: item.user_id } }">
                <v-icon small>mdi-image-multiple</v-icon>
              </v-btn>
            </div>
            <div>
              <v-btn title="View the User Events for this sitting" dark color="accent" small :to="{ path: '/user-events', query: { uid: item.user_id, ed: eventDate(item.started_time) } }">
                <v-icon small>mdi-account-group</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="actions d-flex ml-auto">
            <div class="ml-auto mr-2">
              <v-btn title="View the Assessment Instance for this sitting" dark color="indigo" small :to="{ path: `/assessment-instances/${item.assessment_instance_id}/items` }">
                <v-icon small>mdi-note</v-icon>
              </v-btn>
            </div>
            <div>
              <v-btn title="View responses for this sitting" dark color="teal" small :to="{ path: `/sittings/${item.sitting_id}` }">
                <v-icon small>mdi-view-list-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon';

export default {
  name: 'Sittings',
  data: () => ({
    assessment_instance_id: '',
    assessment_type: '',
    sitting_user_profile_id: '',
    chosenItem: {},
  }),
  computed: {
    ...mapGetters('sittings', [
      'sittings',
      'headers',
      'pagination',
      'isBusy',
      'error',
      'filters',
    ]),
  },
  methods: {
    clearFilter() {
      this.sitting_user_profile_id = ''
      this.assessment_instance_id = ''
      this.assessment_type = ''
      this.handleSubmit()
    },
    eventDate(date) {
      return DateTime.fromISO(date).toFormat('yyyy,MM,dd')
    },
    handleSubmit() {
      const query = { ... this.$route.query }
      if (!this.sitting_user_profile_id) delete query.sitting_user_profile_id
      else query.sitting_user_profile_id = this.sitting_user_profile_id

      if (!this.assessment_instance_id) delete query.assessment_instance_id
      else query.assessment_instance_id = this.assessment_instance_id

      if (!this.assessment_type) delete query.assessment_type
      else query.assessment_type = this.assessment_type

      // Pushing the route will trigger the watcher below, which calls fetchUsers().
      if ((Object.keys(query).length)) this.$router.push({ name: "Sittings", params: this.$route.params, query })
    },
  },
  watch: {
    $route: {
      handler() {
        this.assessment_instance_id = this.$route.query.assessment_instance_id
        this.sitting_user_profile_id = this.$route.query.sitting_user_profile_id
        this.assessment_type = this.$route.query.assessment_type
        this.$store.commit('sittings/SET_FILTERS', {
          assessment_instance_id: this.assessment_instance_id,
          sitting_user_profile_id: this.sitting_user_profile_id,
          assessment_type: this.assessment_type,
        })
        this.$store.dispatch('sittings/fetchSittings')
      },
      immediate: true
    }
  },
  beforeMount() {
    const filters = {
      assessment_instance_id: this.$route.query.assessment_instance_id,
      sitting_user_profile_id: this.$route.query.sitting_user_profile_id,
      assessment_type: this.$route.query.assessment_type,
    }
    this.$store.commit('sittings/SET_FILTERS', filters)
    this.$store.dispatch('sittings/fetchSittings')
  },
}
</script>
<style lang="scss" scoped>

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-btn.v-size--small {
    min-width: 0;
    padding: 0px 8px;
  }
  .v-data-footer__select {
    visibility: hidden;
  }
}

.uuid {
  max-width: 140px;
  display: inline-block;
}

.v-btn {
  font-size: 12px;
  text-transform: initial;
}

.actions {
  margin-right: -20px;
}

</style>